<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('signup.title') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps" v-if="this.$i18n.locale === 'ru'">
              <span class="current">{{ $t('signup.step') }} 1</span>
              <span class="total">{{ $t('signup.out-of') }} 4</span>
            </div>
            <div class="steps" v-else-if="this.$i18n.locale === 'kz'">
              <span class="current">4</span>
              <span class="total">{{ $t('signup.out-of') }} 1{{ $t('signup.ending') }}</span>
            </div>

            <div class="warning-wrapper warning-wrapper-new-signup">
              <div class="warning-img"></div>
              <div class="warning-message" v-html="$t('signup-by-iin.title-message')">

              </div>
            </div>

            <div class="intstruction-wrapper intstruction-wrapper-new-signup">
              <span class="instruction-title">{{ $t('signup-by-iin.instruction') }}</span>
              <a v-if="$i18n.locale=='ru'" download href="/files/ru/RU Инструкция по регистрации ученика на 1000bala 2025.pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
              <a v-if="$i18n.locale=='kz'" download href="/files/kz/KZ оқушыны тіркеу бойынша нұсқаулық 1000bala 2025 .pdf" class="download-instruction">{{ $t('signup-by-iin.download-instruction') }}</a>
              <button  @click="$bvModal.show('youtube-modal')" class="watch-instruction">{{ $t('signup-by-iin.see-instruction') }}</button>
            </div>

            <div class="signin-form row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <form-group
                        class="iin-input"
                        :field="$v.account.iin"
                        :label="$t('signup.iin-label') + $t('references.required-field')"
                    >
                      <el-input
                          clearable
                          maxlength="12"
                          :placeholder="$t('signup.iin-label')"
                          v-model="account.iin"
                          @change="$v.account.iin.$touch()"
                          v-mask="'1###########'"
                          show-word-limit
                      />
                    </form-group>
                    <form-group
                        class="iin-input"
                        :field="$v.account.phone"
                        :label="$t('references.phone-label') + $t('references.required-field')"
                    >
                      <el-input
                          :disabled="phoneVerified"
                          :placeholder="$t('references.phone-placeholder')"
                          v-model="account.phone"
                          v-mask="'+7 (###) ### ##-##'"
                          clearable
                      />
                    </form-group>

                    <button class="button-iin-clear" @click="clearAll()">
                      {{ $t('additional-infoSignup-by-iin.retry') }}
                    </button>
                    <el-button
                        style="color: white; background-color: #409EFF"
                        type="button"
                        class="btn-next"
                        @click="submit"
                    >
                      {{ $t('signup.next-btn') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="youtube-modal" hide-footer hide-header size="lg">
      <div id="error-wrapper">
        <div id="dialog">

          <button @click="$bvModal.hide('youtube-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <iframe  style="width: 100%; height: 500px;" v-if="$i18n.locale=='ru'"  src="https://www.youtube.com/embed/BLdv4hG2-Do" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe  style="width: 100%; height: 500px;" v-else-if="$i18n.locale=='kz'"  src="https://www.youtube.com/embed/oQlb2hrucUI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>
      </div>
    </b-modal>

    <b-modal id="error-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('error-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h3>{{ $t('signup.error-modal') }}</h3>
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </b-modal>
    <b-modal id="sms-modal" hide-footer hide-header>
      <div id="sms-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('sms-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h3>{{ $t('signup.enter-sms-code') }}</h3>
          <span>{{ $t('signup.sended-to') }} {{ account.phone }}</span>
          <div id="form">
            <CodeInput
                :field-width="54"
                :field-height="82"
                :fields="4"
                class="input"
                v-on:change="onChange"
                v-on:complete="onComplete"
            />
            <p class="text-danger" v-if="invalidCode">{{ $t('signup.incorrect-code') }}</p>
          </div>
          <p v-if="canResend"><a @click="resendSms">{{ $t('signup.resend-code') }}</a></p>
          <p v-if="!canResend">{{ $t('signup.resend-code-after') }}{{ resendSec }} {{ $t('signup.resend-code-seconds') }}</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {minLength, minValue, required, email} from "vuelidate/lib/validators"
import CodeInput from "vue-verification-code-input"

export default {
  name: 'UserSignupStep1',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
  data() {
    return {
      errorMessage: '',
      sendingSms: true,
      invalidCode: false,
      canResend: true,
      phoneVerified: false,
      resendSec: 60,
      account: {
        iin: '',
        phone: '',
      }
    }
  },
  validations: {
    account: {
      iin: { required, minLength: minLength(12) },
      phone: { required, minLength: minLength(11) },
    }
  },
  mounted() {
    if (sessionStorage.getItem('account')) {
      this.account = JSON.parse(sessionStorage.getItem('account'));
    }
    if (sessionStorage.getItem('phone-verified')) {
      this.phoneVerified = (sessionStorage.getItem('phone-verified') == 'true');
      if (this.phoneVerified == true && this.account.phone == false) {
        this.phoneVerified = false
        sessionStorage.setItem('phone-verified', JSON.stringify(false));
      }
    }
  },
  methods: {
    clearAll() {
      sessionStorage.removeItem('account');
      sessionStorage.removeItem('phone');
      sessionStorage.removeItem('education');
      sessionStorage.removeItem('phone-verified');
      this.$router.go();
    },
    trimPhone(value) {
      return value
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)[0]
    },
    submit() {
      if (this.phoneVerified) {
        this.$router.push({ name: 'signup-second-step' });
        return;
      }

      this.$http.get(`${window.API_ROOT}/api/user/exists/`, {
        params: {
          iin: this.account.iin,
          phone: this.trimPhone(this.account.phone),
        }
      })
          .then((res) => {
            if (res.body.success) {
              this.openModal();
            }
          })
          .catch((e) => {
            console.log(e);
            Vue.toastr({
              message: this.$t(`signup.${e.body.message}`),
              type: 'error'
            });
          });
    },
    openModal() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      if (this.phoneVerified) {
        sessionStorage.setItem('account', JSON.stringify(this.account));
        sessionStorage.setItem('phone-verified', JSON.stringify(true));
        this.$router.push({path: '/signup-by-IIn/third-step' });
      } else {
        if (this.resendSec == 60) {
          setInterval(() => {
            this.resendSec--;
            if (this.resendSec == 0) {
              this.canResend = true;
            }
          }, 1000);
        }
        if (this.canResend == true) {
          this.canResend = false
          this.sendSms({
            phone: this.trimPhone(this.account.phone),
            locale: this.$i18n.locale
          });
        }
        this.$bvModal.show('sms-modal');
      }
    },
    resendSms() {
      this.canResend = false;
      this.resendSec = 60;
      this.sendSms({
        phone: this.trimPhone(this.account.phone),
        locale: this.$i18n.locale
      });
    },
    onChange(v) {
      // console.log('on change');
    },
    onComplete(v) {
      this.invalidCode = false;
      this.sendingSms = true;
      this.$http.post(window.API_ROOT + '/api/sms-verification', {
        phone: this.trimPhone(this.account.phone),
        code: v,
      }).then((res) => {
        if (res.body.status == 'success') {
          sessionStorage.setItem('account', JSON.stringify(this.account));
          sessionStorage.setItem('phone-verified', true);
          this.$router.push({ name: 'signup-second-step' })
        }
        if (res.body.status == 'error') {
          this.invalidCode = true;
        }
        this.sendingSms = false;
      }).catch(() => {
        this.sendingSms = false;
      })
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    async routeSignupHandler() {
      try {
        const res = await this.$http.get(`${window.API_ROOT}/api/check-time`)
        if (res.body.code === 403) {
          this.$router.push('/')
          Vue.toastr({
            message: '',
            description: this.$t('navbar.registration-alert').replace('year', new Date().getFullYear()),
            type: 'info'
          });
        }
      } catch (error) {
        this.$router.push('/')
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        });
      }
    },
    ...mapActions({
      'sendSms': 'sendSms',
      'checkSms': 'checkSms'
    })
  }
}
</script>

